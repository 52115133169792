<template>
  <b-img
    fluid
    :src="getImageLogo"
    :alt="$t('Logo')"
    :style="{height: height}"
  />
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    height: {
      type: String,
      default: '',
      required: false,
    },
    full: {
      type: Boolean,
      default: false,
      required: false,
    },
    white: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      logo: require('@/assets/images/logo/logo.png'),
      logoFull: require('@/assets/images/logo/logo.png'),
      logoWhite: require('@/assets/images/logo/logo_white.png'),
    }
  },
  computed: {
    getImageLogo() {
      if (this.white === true) {
        return this.logoWhite
      }
      if (this.full === true) {
        return this.logoFull
      }

      return this.logo
    },
  },
}
</script>
