export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorGreaterThanZero = value => {
  const regExp = /^([0-9]+)$/
  const validNumber = regExp.test(value)

  if (validNumber) {
    if (value > 0) {
      return true
    }
  }

  return false
}

export const validatorNumberVueTelInput = (value, args) => {
  try {
    if (args.phone.valid === true) {
      return true
    }
  } catch (err) {
    //
  }

  return false
}

export const getMsgValidatorNumberVueTelInput = (value, args) => {
  try {
    if (typeof args.phone.countryCallingCode === 'undefined') {
      return ['Preencha o indicativo do telemóvel']
    }

    if (args.phone.valid === false) {
      return ['Número inválido']
    }
  } catch (err) {
    //
  }

  return false
}

export const validatorNumberNIF = value => {
  const contribuinte = value.toUpperCase()
  let error = 0
  let comparador = 0

  if (contribuinte) {
    if (
      contribuinte.substr(0, 1) !== '1' // pessoa singular
      && contribuinte.substr(0, 1) !== '2' // pessoa singular
      && contribuinte.substr(0, 1) !== '3' // pessoa singular
      && contribuinte.substr(0, 2) !== '45' // pessoa singular não residente
      && contribuinte.substr(0, 1) !== '5' // pessoa colectiva
      && contribuinte.substr(0, 1) !== '6' // administração pública
      && contribuinte.substr(0, 2) !== '70' // herança indivisa
      && contribuinte.substr(0, 2) !== '74' // herança indivisa
      && contribuinte.substr(0, 2) !== '75' // herança indivisa
      && contribuinte.substr(0, 2) !== '71' // pessoa colectiva não residente
      && contribuinte.substr(0, 2) !== '72' // fundos de investimento
      && contribuinte.substr(0, 2) !== '77' // atribuição oficiosa
      && contribuinte.substr(0, 2) !== '78' // ---
      && contribuinte.substr(0, 2) !== '79' // regime excepcional
      && contribuinte.substr(0, 1) !== '8' // empresário em nome individual (extinto)
      && contribuinte.substr(0, 2) !== '90' // condominios e sociedades irregulares
      && contribuinte.substr(0, 2) !== '91' // condominios e sociedades irregulares
      && contribuinte.substr(0, 2) !== '98' // não residentes
      && contribuinte.substr(0, 2) !== '99' // sociedades civis
    ) {
      error = 1
    }

    const check1 = contribuinte.substr(0, 1) * 9
    const check2 = contribuinte.substr(1, 1) * 8
    const check3 = contribuinte.substr(2, 1) * 7
    const check4 = contribuinte.substr(3, 1) * 6
    const check5 = contribuinte.substr(4, 1) * 5
    const check6 = contribuinte.substr(5, 1) * 4
    const check7 = contribuinte.substr(6, 1) * 3
    const check8 = contribuinte.substr(7, 1) * 2
    const total = check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8
    const divisao = total / 11
    const modulo11 = total - parseInt(divisao, 10) * 11

    if (modulo11 === 1 || modulo11 === 0) {
      comparador = 0
    } else {
      comparador = 11 - modulo11
    }

    const ultimoDigito = contribuinte.substr(8, 1) * 1
    if (ultimoDigito !== comparador) {
      error = 1
    }
  }

  if (error === 1) {
    return false
  }

  return true
}

export const validatorMaxValueValidate = (value, args) => {
  const regExp = /^([0-9]+)$/
  const validNumber = regExp.test(value)

  if (validNumber) {
    if (Number(value) <= Number(args[0])) {
      return true
    }
  }

  return false
}
